import { useRef, useState, useEffect } from "react";
import Select from "react-select";
const _ = require("lodash");

// get APIKEY here if expired:
// https://www.bart.gov/schedules/developers/api
const APIKEY = "MW9S-E7SL-26DU-VV8V";
const API = "https://api.bart.gov/api";

const ETA = ({ eta }) => {
  return (
    <div className="m-2 w-28 flex justify-between items-center rounded-lg bg-white shadow-lg p-1 px-2">
      <div className="font-bold text-2xl text-gray-700">
        {eta.minutes}
        <span className="text-xs font-normal">m</span>
      </div>
      <div
        style={{ backgroundColor: eta.hexcolor }}
        className={
          "p-1 px-2 rounded-lg text-xs " +
          (eta.color === "YELLOW" ? "text-yellow-900" : "text-white")
        }
      >
        {eta.destination}
      </div>
    </div>
  );
};

const BartWidget = ({ station, dir }) => {
  const s = useRef({}).current;
  const [data, setData] = useState({});

  const refresh = async () => {
    const url = `${API}/etd.aspx?cmd=etd&orig=${station}&key=${APIKEY}&json=y`;
    const data = await (await fetch(url)).json();
    setData(data.root.station[0]);
  };
  s.refresh = refresh;

  useEffect(() => {
    let interval = null;
    if (station && dir) {
      interval = setInterval(() => {
        s.refresh();
      }, 1000 * 60);
      refresh();
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [station]);

  const etas = [];
  _.each(data.etd, (dest) => {
    _.each(dest.estimate, (est) => {
      if (est.direction.toUpperCase() === dir) {
        if (est.minutes === "Leaving") {
          est.minutes = 0;
        } else {
          est.minutes = parseInt(est.minutes, 10);
        }
        etas.push({ destination: dest.abbreviation, ...est });
      }
    });
  });
  const sorted = _.sortBy(etas, (eta) => eta.minutes);
  return (
    <div className="flex max-w-md flex-wrap flex-row justify-center">
      {_.map(sorted, (eta, idx) => (
        <ETA key={idx} eta={eta} />
      ))}
      {/* {JSON.stringify(data, 0, 2)} */}
    </div>
  );
};

const SelectBar = ({ onChange, value, options }) => {
  const onClick = (idx) => {
    onChange(options[idx].value);
  };
  return (
    <div className="flex flex-row text-lg bg-gray-100 rounded">
      {_.map(options, (o, i) => {
        const selected = o.value === value;
        return (
          <div
            className={
              "p-1 px-2 rounded cursor-pointer hover:bg-gray-200 " +
              (selected
                ? "hover:bg-gray-500 bg-gray-500 text-white"
                : "text-gray-400")
            }
            key={i}
            onClick={() => onClick(i)}
          >
            {o.label}
          </div>
        );
      })}
    </div>
  );
};

const StationPicker = ({ station, setStation, dir, setDir }) => {
  const [stations, setStations] = useState([]);

  const refresh = async () => {
    const url = `${API}/stn.aspx?cmd=stns&key=${APIKEY}&json=y`;
    const data = await (await fetch(url)).json();
    setStations(data.root.stations.station);
  };

  useEffect(() => {
    refresh();
  }, []);

  const onChangeStation = (s) => {
    setStation(s);
    window.location.hash = `${s}-${dir}`;
  };

  const onChangeDir = (d) => {
    setDir(d);
    window.location.hash = `${station}-${d}`;
  };

  const getOption = (abbr) => {
    const found = _.find(stations, (s) => s.abbr === abbr);
    if (!found) {
      return null;
    }
    return {
      value: abbr,
      label: found.name
    };
  };

  return (
    <div className="flex flex-wrap items-center justify-center">
      <Select
        onChange={(opt) => onChangeStation(opt.value)}
        className="w-60 my-1 mx-1"
        value={getOption(station)}
        options={_.map(stations, (s) => ({ label: s.name, value: s.abbr }))}
      />
      <div className="mx-1 my-1">
        <SelectBar
          value={dir}
          onChange={onChangeDir}
          options={[
            { value: "SOUTH", label: "South" },
            { value: "NORTH", label: "North" }
          ]}
        />
      </div>
    </div>
  );
};

export default function App() {
  const [station, setStation] = useState();
  const [dir, setDir] = useState();

  useEffect(() => {
    if (window.location.hash) {
      const [station, dir] = window.location.hash.split("#")[1].split("-");
      setStation(station);
      setDir(dir);
    } else {
      setStation("CIVC");
      setDir("NORTH");
    }
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col space-y-5 items-center justify-center p-5">
      <StationPicker
        station={station}
        dir={dir}
        setDir={setDir}
        setStation={setStation}
      />
      <BartWidget station={station} dir={dir} />
    </div>
  );
}
